export const environment = {
    production: true,
    environmentName: 'prod',
    baseUrl:'https://spellinghero.app/',
    backendUrl:'https://custom-auth-153691763486.northamerica-northeast1.run.app/',
    apiUrl:'https://ui-interaction-153691763486.northamerica-northeast1.run.app/',
    region:'northamerica-northeast1',
    recaptchaSiteKey:"6LcjZzQqAAAAAHGyX9c7O0p4mcxd7ldXr8nCEEIj",
    stripePublishableKey:"pk_live_51NeMKILUPLIOScuQGHrhZ5h2v86Ii4sQctzfRBl2M2Gz3gyoP0R7DTTdsEB5qZdurcvrmnAHkjWI3D8UYpzYosUr00JnCEJaAy",
    firebase: {
      apiKey: "AIzaSyBfs56kE9RVHKGnlj6wsj31GviIpBcuKok",
      authDomain: "spelling-app-p.firebaseapp.com",
      projectId: "spelling-app-p",
      storageBucket: "spelling-app-p.appspot.com",
      messagingSenderId: "153691763486",
      appId: "1:153691763486:web:94cb545370c2cf5f534f0f",
      measurementId: "G-NT9J2W64W5"
      // ... other Firebase config
    }
  };
  